// make a delete page just have one input field and a button to delete the data  is just email field and alert message if the field has input data and clicked 
import React, { useState } from 'react'
import { Button, TextField, Box, Typography, Container } from '@mui/material'
import Logo from "../images/favicon.png";
import { toast } from "react-toastify";
const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    rtl: false,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    progress: undefined,
    theme: "light",
};



const Delete = () => {
    const [email, setEmail] = useState('')

    const handleDelete = () => {
        if (email !== '') {
            // delay for 2 seconds
            setTimeout(() => {
                toast.success("We received your request. We will get you soon!", toastOptions);
            }, 1000)
            setEmail('')
        }
    }

    return (
        <Container component="main" maxWidth="xl" sx={{
            backgroundColor: 'secondary.main',
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

        }}>
            <Box sx={{
                display: 'flex', justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: 'secondary.main',
                padding: 3,
                borderRadius: 2,
                boxShadow: 2,
            }}>
                <img src={Logo} alt="Logo" style={{ width: '50px', marginLeft: '20px' }} />
                <Typography variant="h5" sx={{ marginTop: 2, textAlign: 'center', color: 'black', fontWeight: 'bold' }}>
                    Delete User
                </Typography>
                <Box
                    noValidate sx={{ mt: 1 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleDelete}
                    >

                        Delete
                    </Button>
                </Box>
            </Box>
        </Container>

    )
}

export default Delete